import { createApp } from 'vue';
import { createYmaps } from 'vue-yandex-maps';

require('@/assets/owl-carousel/owl.carousel.min.css')
require('@/assets/owl-carousel/owl.theme.default.min.css')

require('@/assets/styles/main.css')
require("@vueform/slider/themes/default.css")

//require('@assets/js/owl.carousel.min.js')

import LoadScript from "vue-plugin-load-script";

import { createRouter,createWebHashHistory } from 'vue-router';

import store from './store';
import mixin from './plugins/mixin';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome';
import VueCarousel from '@chenfengyuan/vue-carousel';

import App from './App.vue';
//Components
import PageHeader from './components/PageHeader.vue';
import PageHeaderLogged from './components/PageHeaderLogged.vue';
import Date from './components/Date.vue';
import Photo from './components/Photo.vue';
import BottomPanel from './components/BottomPanel.vue';
import UserFull from './components/UserFull.vue';
import UserSmall from './components/UserSmall.vue';
import PlaceItem from './components/PlaceItem.vue';
import MenuPanel from './components/MenuPanel.vue';
import FilterPanel from './components/FilterPanel.vue';
import TargetPanel from './components/TargetPanel.vue';

//Pages
import Welcome from './pages/Welcome.vue';
import RegistrationView from './pages/RegistrationView.vue';
import LoginView from './pages/LoginView.vue';
import ProfileView from './pages/ProfileView.vue';
import InfoView from './pages/InfoView.vue';
import SettingsView from './pages/SettingsView.vue';
import SupportView from './pages/SupportView.vue';
import UserView from './pages/UserView.vue';
import ListUsersView from './pages/ListUsersView.vue';
import ChatListView from './pages/ChatListView.vue';
import ChatView from './pages/ChatView.vue';
import MenuView from './pages/MenuView.vue';
import MapView from './pages/MapView.vue';
import PlacesView from './pages/PlacesView.vue';


const routes = [{ path: '/', component: Welcome}, {path: '/profile', component:ProfileView, meta: { requiresAuth: true }}, 
                { path: '/login', component: LoginView},{ path: '/intro', component: RegistrationView},{ path: '/info/:id', component: InfoView, meta: { requiresAuth: true }},
                { path: '/changeemail', component: SettingsView, meta: { requiresAuth: true }},
                { path: '/changepass', component: SettingsView, meta: { requiresAuth: true }},
                { path: '/support', component: SupportView, meta: { requiresAuth: true }},
                { path: '/places', component: PlacesView, meta: { requiresAuth: true }},
                 { path: '/checkin', component: MapView, meta: { requiresAuth: true }},
                 { path: '/checkin/:id', component: MapView, meta: { requiresAuth: true }},
                  { path: '/view', component: UserView, meta: { requiresAuth: true }},
                { path: '/match', component: ListUsersView, meta: { requiresAuth: true }},
                { path: '/match/:place', component: ListUsersView, meta: { requiresAuth: true }},
                
                { path: '/menu', component: MenuView, meta: { requiresAuth: true }},
                        { path: '/chats/', component: ChatListView, meta: { requiresAuth: true }},
                { path: '/chat/:id', component: ChatView, meta: { requiresAuth: true }},
                ];
const router = createRouter({routes: routes,history: createWebHashHistory()})
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
const app=createApp(App);

app.mixin(mixin);
app.use(router);
app.use(store);
app.use(LoadScript);
app.use(createYmaps({apikey: mixin.methods.config('yApiKey')}));
app.component("font-awesome-icon", FontAwesomeIcon)
app.component(VueCarousel.name, VueCarousel);
app.component("PageHeader", PageHeader)
app.component("PageHeaderLogged", PageHeaderLogged)
app.component("Date", Date)
app.component("Photo", Photo)
app.component("BottomPanel", BottomPanel)
app.component("MenuPanel", MenuPanel)
app.component("FilterPanel", FilterPanel)
app.component("TargetPanel", TargetPanel)
app.component("UserFull", UserFull)
app.component("UserSmall", UserSmall)
app.component("PlaceItem", PlaceItem)
app.mount('#app');
const isAuthenticated = () => store.state.auth.status.loggedIn;
let cc = router.currentRoute.value.path;

router.beforeEach((to, from) => {
	if ((to.path == '/' || to.path == '/register' || to.path == '/login') && isAuthenticated()) {
		router.push('/profile');
	}
	else {
		if (to.matched.some((route) => route.meta?.requiresAuth)) {
			if (isAuthenticated()) {
				return true;
			} 	
			else {
				return '/';
	    	}
		}
	}
});


