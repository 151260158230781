<template>
  	
  	  <div class=" userfull_wrapper">
 		
  	
 	<carousel :items-to-show="1" :mouse-drag="false" :touch-drag="false">
    		<slide v-for="photo in photos" :key="photo">
  					<div class="profile_photo_wrapper " :class="photo.class" :style="{ backgroundImage: 'url(' + photo.x + ')' }"></div>   
    		</slide>
    		  <template #addons>
      <Pagination/>
      <Navigation/>
 
    </template>
 
	</carousel>
 	<div class="gradient">
 	<div class="profile_title_username  loadingel">{{ username }}</div>
	<div class="goals_wrapper">
	<div class="profile_info_wrapper">
	
	<ul class="profile_info">
	<li><div class="loadingel">{{ height }} см</div></li>
	<li><div class="loadingel">{{ children }}</div></li>
	</ul> 
	<div><div class="writemessage" @click="viewChat"></div> 
	</div>
	</div>
	
	<ul class="goals">
		<li v-for="item in goals">
		   <div class="item" :class="{ active: item.active }"> {{ item.name }} </div>
  		</li>
	</ul>
	</div>	</div>
	</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination,Navigation } from 'vue3-carousel'

export default {
	name: 'UserFull', 
	props:{
		user:{
		    required: true,
		    
		  }
	},
	components: {
    Carousel,
    Slide,
    Pagination,Navigation
  },
	data: function () {
    return {
     loading:true,
      children: 'children',
      username: 'username',
      id: -1,
      height: 'height',
      photos:[],
      goals:[],
    }
  },
  methods: {
	  
	  viewChat(){
	  	this.$router.push('/chat/'+this.id);
	  }
  },
  mounted(){
     this.loading = false;
	$(".goals_wrapper").mousemove(function(){
		return false;
	})
	$(".goals_wrapper .goals").bind('touchmove', (e) => { e.stopPropagation(); });
	$(".goals_wrapper .goals").bind('touchstart', (e) => { e.stopPropagation(); }); 
	 this.username = this.user.name +', '+this.user.age;
	            this.height = this.user.height == null ? '170 см' : this.user.height;
	            this.children = this.formatChildren(this.user.children);
	            if (this.user.photos!=undefined)
		            this.photos = this.user.photos;
	            if (this.user.goals!=undefined)
				{
					for(let i = 0;i<this.user.goals.length;i++){
						this.goals.push({name:this.user.goals[i].name,'active':this.user.goals[i].active});
						
					}
				}
				console.log(this.goals);
	            if (this.user.photo!=null)
		            this.photos.unshift(this.user.photo)
	            if (this.photos.length == 0){
	            	this.photos.push({class:'nophoto'});
	            }
	            
	            this.id = this.user.uniq;
				setTimeout(function(){
					let w = $(".cat_list_users_wrapper").width();
					$(".goals .item").css('max-width',w / 3 - 20);	
				},100)
  },
  	
    watch: {
    loading: ()=>{$(".loadingel").removeClass('loadingel');}
   }
 }
 </script>