<template>
<form class="filterform" @submit.prevent="saveFilters">
<div class="filter_panel"  :class="{'filter_panelshow':show==true }" @click="close"><div class="wrapper graybg">
<div class="overflow_filters">

<div class="loadingel">

<div class="foilter-item"><div class="filter_title filter_title_bigcenter">Цели</div>

<div class="stv-radio-tabs-wrapper stv-radio-tabs-wrapper-hor" id="goals_wrapper">
</div>
</div>
</div>
</div>
</div>
</div></form>
<div class="popupmessage_wrapper">
	<div class="popupmessage">Оставьте выбранной<br>хотя бы одну цель для<br>успешного поиска! <div class="button pinkfill" onclick="$(this).parents('.popupmessage_wrapper').hide()">Понятно</div></div>
</div>
</template>
<script>
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/css";
import { reactive } from "vue";
import Slider from '@vueform/slider'
let topScroll = 0;
export default {

  components: { VueSimpleRangeSlider, Slider },
  data(){
      return {
      		years : reactive({ range: [0, 20], number: 1 }),
    		height : reactive({ range: [150, 212], number: 1 }),
    		show:false,
    		data:null,
    		inityears:{min:0,max:21},
    		initheights:{min:150,max:212},
    		newslider:[10,1000],
    		
       }
    },
    created(){},
    mounted() {
	 	this.updateyears(this.years.range);
	 	this.updateheight(this.height.range);
	 	$(".stv-radio-tabs-wrapper input").change(function(){
	 	$(".stv-radio-tabs-wrapper label.selected").removeClass('selected');
	 		$(this).find('+label').addClass('selected selected'+$(this).attr('value'));
	 		
	 	})
	 	$(".overflow_filters").scroll(function () {
    		topScroll = $(".overflow_filters").scrollTop();
		})
		$(".overflow_filters").height($(window).height() - 140);
	 	
  },
  unmounted() {
        if (this.show){
         this.saveFilters();
         $("body").removeClass('bodynosroll');
			
        }
  },
  methods:{
  	updateyears(x){
  		$(".rangeslideryears .alt").html(x[0]+'-'+x[1]+' лет');
  		$("input[name=age]").val(x[0]+'-'+x[1]);
  	},
  		updateheight(x){
  		$(".rangesliderheight .alt").html(x[0]+'-'+x[1]+' см')
  		$("input[name=height]").val(x[0]+'-'+x[1]);
  	},
  	
  	saveFilters(){
  		$(".buttonacceptfilter").prop('disabled',true);
  		this.$store.dispatch("user/saveTargets", $(".filterform")[0]).then(
	        (answer) => {
	        }
	    )
	 	
  		return false;
  	},
  	loadingInfo(){
  		this.data = '';
  		 this.$store.dispatch("user/targets").then(
	        (filter) => {
	
	
	 	   		$('#goals_wrapper').html('');
	       		$(filter.goals).each(function(){
	       			
	       			let checked = false;
	       			let id = this.id
	       			$(filter.usergoals).each(function(){
	       				if (id == parseInt(this)) checked=true;
	       			});
	       			
	       			let appendItem = '<input type="checkbox" class="stv-radio-tab" name="goals[]" value="'+this.id+'" id="tab1x'+this.id+'" '+(checked?'checked':'')+' /><label for="tab1x'+this.id+'">'+this.name+'</label>'
	       			$('#goals_wrapper').append(appendItem)	;
	       		})
	       		$('#goals_wrapper input[type=checkbox]').click(function(){
					$(".overflow_filters").scrollTop(topScroll);
	       			let countChecked = $('#goals_wrapper input[type=checkbox]:checked').length;
	       			if (countChecked == 0){
	       				$(this).prop('checked', true);
	       				$(".popupmessage_wrapper").show().height($(window).height());
	       				
	       			}
	       		})
	       		$(".sel_wrapper").click(function(event){
	       		event.preventDefault();
//	       			$(this).find('select').trigger('click');
	       		})
	        	$(".loadingel").removeClass('loadingel');
  		})
  	},
  },
  watch: {
		  show: {
			     handler(val){
			        if (!val){
			    	     this.saveFilters();    
			    	     $("body").removeClass('bodynosroll');
						$(".settingfilters").hide();
						$(".viewmyprofilebtn").show();
					}
					else
					{
						$("body").addClass('bodynosroll');
						$(".settingfilters").show();
						$(".viewmyprofilebtn").hide();
						
						this.loadingInfo();
					}
					
			     },
			     deep: true
			  }
		  },

}
</script>
