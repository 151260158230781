<template>
<form class="filterform" @submit.prevent="saveFilters">
<div class="filter_panel filter_panel_main"  :class="{'filter_panelshow':show==true }" @click="close"><div class="wrapper graybg">
<div class="overflow_filters">
<div class="filter_top_title">Фильтры</div>
<div class="loadingel">
<div class="foilter-item  " ><div class="filter_title">Мне интересны</div>

<div class="stv-radio-tabs-wrapper">
	<input type="radio" class="stv-radio-tab" name="who" value="1" id="tab1" data-name="Парни" checked />
	<label for="tab1">Парни</label>
	<input type="radio" class="stv-radio-tab" name="who" value="2" id="tab2" data-name="Девушки" />
	<label for="tab2">Девушки</label>
	<input type="radio" class="stv-radio-tab" name="who" value="0" id="tab3" data-name="Неважно" />
	<label for="tab3">Неважно</label>
	
	
</div></div>
</div>
<div class="loadingel">
<div class="foilter-item ">
	<div class="filter_title">Возраст</div>
	<input type="hidden" name="age" value="">
	
	<div class="range_slider_wrapper">
	<Slider
      v-model=years.range
      :min=inityears.min
      :max=inityears.max
      :tooltips=false
      :showTooltip="drag"
      @slide = "updateyears"
      
    ></Slider>
	</div>
	<div class="rangeslider rangeslideryears">
		
		<div class="alt"></div> 
	</div>
</div>
</div>
<div class="loadingel">

<div class="foilter-item ">
	<div class="filter_title">Рост</div>
	<input type="hidden" name="height" value="">
	<div class="range_slider_wrapper">
	<Slider
      v-model=height.range
      :min=initheights.min
      :max=initheights.max
      :tooltips=false
      :showTooltip="drag"
        @slide = "updateheight"
   
    ></Slider></div>
	<div class="rangeslider rangesliderheight">
		
		<div class="alt"></div> 
	</div>
</div>
</div>
<div class="loadingel">

<div class="foilter-item foilter-item-flex  loadingel"><div class="filter_title">Дети</div>
	<div class="sel_wrapper"><select name="children">
   					<option value="null">Неважно</option>
   					<option value="1">Нет</option>
   					<option value="2">Есть</option>
   				</select>
   	</div>			
</div>

</div>
<div class="loadingel">

<div class="foilter-item foilter-item-flex">
	<input type="checkbox" id="onlyphoto" class="custom-checkbox" name="onlyphoto" value=1 checked>
	<label class="filter_title" for="onlyphoto">Только с фото</label> 
	</div>
</div>
<div class="loadingel">

<div class="foilter-item"><div class="filter_title filter_title_bigcenter">Цели</div>

<div class="stv-radio-tabs-wrapper stv-radio-tabs-wrapper-hor" id="goals_wrapper">
</div>
</div>
</div>
</div>
</div>
</div></form>
<div class="popupmessage_wrapper popupmessage_wrapper_onetarget">
	<div class="popupmessage">Оставьте выбранной<br>хотя бы одну цель для<br>успешного поиска! <div class="button pinkfill" onclick="$(this).parents('.popupmessage_wrapper').hide()">Понятно</div></div>
</div>
<div class="popupmessage_wrapper popupmessage_wrapper_noequaltaget">
	<div class="popupmessage">
<p class="red">Упс!<br>
Несовместимый выбор<br>
пола и целей</p>
<p>
У вас в Фильтрах выбран пол:<br>
<span id="selectedgender"></span></p>

<p class="red">Наш сервис не мэтчит<br>
пользователей одного пола<br>
с целями:</p>
<p>Любовь, отношения,<br>
создание семьи</p>
<p>Свидание, ужин,<br>
прогулка, кофе</p>
<p class="red">Выберите другие цели знакомства<br>
или поменяйте пол в Фильтрах</p>

 <div class="button pinkfill" onclick="$(this).parents('.popupmessage_wrapper').hide()">Понятно</div></div>
</div>

</template>
<script>
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/css";
import { reactive } from "vue";
import Slider from '@vueform/slider'
let topScroll = 0;
export default {

  components: { VueSimpleRangeSlider, Slider },
  data(){
      return {
      		years : reactive({ range: [0, 20], number: 1 }),
    		height : reactive({ range: [150, 212], number: 1 }),
    		show:false,
    		data:null,
    		inityears:{min:0,max:21},
    		initheights:{min:150,max:212},
    		newslider:[10,1000],
    		mygender:0,
    		
       }
    },
    created(){},
    mounted() {
	 	this.updateyears(this.years.range);
	 	this.updateheight(this.height.range);
	 	$(".stv-radio-tabs-wrapper input").change(function(){
	 	$(".stv-radio-tabs-wrapper label.selected").removeClass('selected');
	 		$(this).find('+label').addClass('selected selected'+$(this).attr('value'));
	 		
	 	})
	 	$(".overflow_filters").scroll(function () {
    		topScroll = $(".overflow_filters").scrollTop();
		})
		$(".overflow_filters").height($(window).height() - 140);
	 	
  },
  unmounted() {
        if (this.show){
         this.saveFilters();
         $("body").removeClass('bodynosroll');
			
        }
  },
  methods:{
    	checkData(){
    	let sel = $("input[name=who]:checked").val();
  		if (this.mygender == sel || sel == 0){
  			let isOk = true;
  			$("#goals_wrapper input[type=checkbox]:checked").each(function(){
  				if (this.value == 2 || this.value == 6 || this.value == 7) isOk = false;
  			})
  			if (!isOk){
  				$(".popupmessage_wrapper_noequaltaget").show();
  				$("#selectedgender").html($("input[name=who]:checked").data('name'));
		  		return false;
  			}
  		}
  		
  		return true;
  		
  	},
  
  	updateyears(x){
  		$(".rangeslideryears .alt").html(x[0]+'-'+x[1]+' лет');
  		$("input[name=age]").val(x[0]+'-'+x[1]);
  	},
  		updateheight(x){
  		$(".rangesliderheight .alt").html(x[0]+'-'+x[1]+' см')
  		$("input[name=height]").val(x[0]+'-'+x[1]);
  	},
  	
  	saveFilters(){
  		$(".buttonacceptfilter").prop('disabled',true);
  		this.$store.dispatch("user/saveFilters", $(".filterform")[0]).then(
	        (answer) => {
	        		location.reload();
	        }
	    )
	 	
  		return false;
  	},
  	loadingInfo(){
  		this.data = '';
  		 this.$store.dispatch("user/filters").then(
	        (filter) => {
	
	
	 	       	this.inityears = {min:filter.ages.min,max:filter.ages.max};
	        	this.years = 	reactive({ range: [parseInt(filter.user.agestart), parseInt(filter.user.ageend)], number: 10 });
	        	this.updateyears(this.years.range);
        		this.initheights = {min:filter.heights.min,max:filter.heights.max};
	         	this.mygender = filter.mygender;
	         	this.height = 	reactive({ range: [parseInt(filter.user.heightstart), parseInt(filter.user.heightend)], number: 5 });
	       		this.updateheight(this.height.range);
	       		$('#goals_wrapper').html('');
	       		$(filter.goals).each(function(){
	       			
	       			let checked = false;
	       			let id = this.id
	       			$(filter.user.goals).each(function(){
	       				if (id == parseInt(this)) checked=true;
	       			});
	       			
	       			let appendItem = '<input type="checkbox" class="stv-radio-tab" name="goals[]" value="'+this.id+'" id="tab1x'+this.id+'" '+(checked?'checked':'')+' /><label for="tab1x'+this.id+'">'+this.name+'</label>'
	       			$('#goals_wrapper').append(appendItem)	;
	       		})
	       		$('#goals_wrapper input[type=checkbox]').click(function(){
					$(".overflow_filters").scrollTop(topScroll);
	       			let countChecked = $('#goals_wrapper input[type=checkbox]:checked').length;
	       			if (countChecked == 0){
	       				$(this).prop('checked', true);
	       				$(".popupmessage_wrapper_onetarget").show().height($(window).height());
	       				
	       			}
	       		})
	       		$('input[name=who]').each(function(){
	       			let checked = $(this).val() == filter.user.who;
	       			$(this).prop('checked',checked);
	       			if (checked) $(this).find('+label').addClass('selected selected'+$(this).attr('value'));
	       		});
	       		if (filter.user.children>0) 	
		       		$("select[name=children] option[value="+filter.user.children+"]").prop('selected',true);
	       		$("input[name=onlyphoto]").prop('checked', filter.user.photo=="1");
	       		$(".sel_wrapper").click(function(event){
	       		event.preventDefault();
//	       			$(this).find('select').trigger('click');
	       		})
	        	$(".loadingel").removeClass('loadingel');
  		})
  	},
  },
  watch: {
		  show: {
			     handler(val){
			        if (!val){ this.saveFilters();      $("body").removeClass('bodynosroll');
	}
					else
					{
						$("body").addClass('bodynosroll');
						this.loadingInfo();
					}
					
			     },
			     deep: true
			  }
		  },

}
</script>
