<template>
    <PageHeaderLogged title="Мэтч" menu="false" />
    <div class="icon_wrapper_users"><div class="icon setting" @click="showFilters">Фильтры</div><div class="icon i"  @click="showInfo"></div></div>
 <div class="cat_list_users_wrapper wrapper graybg fullheight">
  		  <div v-show="emptyshow">
  <div class="emptlist"  >Список пользователeй пуст</div>
</div>
 <div class="car__list_users">
    <div class="owl-carousel owl-theme" >
		<div class="item" v-for="user in users" :key="user">	 <UserFull v-bind:user="user"  /></div>
    </div>
 </div>

<div class="popupmessage_wrapper popupmessage_wrapper_userempty">
	<div class="popupmessage">В данном месте<br>
нет пользователей!<br><br><br>
Попробуйте поменять фильтры<br>
или выберите другое место<br>
для мэтча </div>



</div>


<div class="userlist_places_list" v-show="userlist_places_show"><div class="placelist" @click="updatePage"><PlaceItem v-for="place in places" :place="place" /></div><div><div class="usercount"><span>{{count}}</span></div></div></div></div>

<div class="popupmessage_wrapper popupmessage_wrapper_useplaces">
	<div class="popupmessage">Не выбрано место для мэтча!<br><br><br>
Перейдите на страницу <strong @click="gotocheckin">Чекин</strong><br><br>
Выберите на карте место,<br>
в котором сейчас находитесь<br>
и зачекиньтесь в нем <div class="messagemap"></div></div>



</div>




     <BottomPanel activeusers="active"/>
 <modal-window ref="info">
		<template v-slot:title>
                <h3 class="modal-title">Справка</h3>
        </template>
 		<template v-slot:body>Текст справки</template>
 	    <template v-slot:footer-okbtn>
        </template>
	</modal-window>
	<FilterPanel ref="filter"></FilterPanel>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
  import ModalWindow from '../components/modal-window.vue'
  import FilterPanel from '../components/FilterPanel.vue'
  


export default {
  name: 'ListUsersView',
components: {
       ModalWindow,FilterPanel
  },  
  props: {
    msg: String
  },
  data() {
    	return {
    		users:[],
    		emptyshow:false,
    		sloaded:false,
    		places:[],
    		showfilterpanel:true,
    		count:0,
    		userlist_places_show:false,
    		scriptLoaded:false,
    		
    		
		}
	},
	mounted(){
	

 		this.setHeight('fullheight');


 
 

	  	
	},
	
	methods: {
	loadData(){
	this.places = [];
//	this.users = [];
	let filter = {place:-1};
 		if (this.$route.params.place)
	 		filter.place = this.$route.params.place;
 	   this.$store.dispatch("list/getProfiles",filter).then(
	        (data) => {
	        	if (data.success == 0){
	        		$(".emptlist").html('');$(".popupmessage_wrapper_useplaces").show();
		            this.emptyshow =  true;
		            $(".icon.setting").addClass('disabled');
		            return;
	        	}
	        	this.userlist_places_show = true;
	            this.loading = false;
	            this.users = data.data.users;
	            this.count = data.data.max>99 ? '99+': data.data.max;
	            data.data.place.one=true;
//	            data.data.place.min=10;
	            data.data.place.current=1;
	            
	            this.places.push(data.data.place);
	            
	            this.emptyshow =  this.users.length == 0;
	            if (this.emptyshow){
					$(".emptlist").html('');$(".popupmessage_wrapper_userempty").show();
		     //		$(".userlist_places_list").addClass('emtyyplace');
		     }
	            
	        },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
	},
	updatePage(){
		location.reload();
	//	this.loadData();
	},
	showInfo(){
		this.$refs.info.show=true;
	},
	gotocheckin(){
				this.$router.push('/checkin/');
   
	},
	showFilters(){
		if (this.places.length == 0) return false;
		
		if (!this.$refs.filter.show ||  this.$refs.filter.show && this.$refs.filter.checkData())
		{
			this.$refs.filter.show=!this.$refs.filter.show;
			$(".icon.setting").toggleClass('isclose');
		}
		
	},
	},
	
	created() {
 		this.loadData();
  
 
   },
   
    watch: {
    users: ()=>{  
				
    			 loadScript("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js")
    			 
    .then(() => {
    			
	            $('.owl-carousel').owlCarousel({items:1,dots:false});
	            
    })
    .catch(() => {
      // Failed to fetch script
    });
    			
	    
    
     }
   }
}
</script>